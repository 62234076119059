<template>
  <div>
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex" aria-label="Tabs">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="{ name: tab.route }"
          exact-active-class="border-blue-600 text-blue-600 hover:text-blue-600 hover:border-blue-500 font-medium"
          class="
            border-transparent
            text-gray-500
            hover:text-gray-700
            hover:border-gray-300
            w-1/2
            py-4
            px-1
            text-center
            border-b-2
            font-medium
            text-sm
          "
        >
          {{ tab.name }}
        </router-link>
      </nav>
    </div>
  </div>
  <router-view />
</template>

<script>
const tabs = [
  { name: "Active", route: "actions-active" },
  { name: "Completed", route: "actions-completed" },
];

export default {
  setup() {
    return {
      tabs,
    };
  },
};
</script>